/*  $addClass-JS

Purpose: tag <body> with ".js" for style assist
================================================== */
$('body').addClass('js');

/*  Remove empty paragraphs from AEM markup
    Purpose: header markup coming from AEM has logic
    found here: /apps/platform/aem-core/components/
    page/default-page/first-level-nav-content.html
    that is causing empty paragraph tag in header.
    Because <p> has visual dimension rules like margin
    and type size and leading this is causing an
    'empty' space to render on screen above Pearson
    logo/.info-menu el. Since the template logic (AEM)
    is written in such a way that it is rendering some
    whitespace between the opening and closing <p>
    tags we cannot use :empty as a valid selector to
    suppress the display of these els.

    Should be noted that this is a temp fix until AEM
    team can revisit the logic found in that template.
    ================================================== */
function removeEmptyParaFromAEMHeader() {
    const paras = $('.js-header-component p');
    paras.each((i, p) => {
        if (p.innerHTML.trim().length === 0) {
            p.parentNode.removeChild(p);
        }
    });
}
window.addEventListener('load', () => {
    removeEmptyParaFromAEMHeader();
});

// Code is commented in scope of PMCCP-110394
// For AU and NZ locales we have Algolia autocomplete and code below doesn't have to run

// $autocomplete
// Need to wait for the header to load
// $(window).on('load', () => {
//     // Trigger Autocomplete!
//     if (typeof addAutocomplete !== 'undefined') {
//         // Add autocomplete for search input
//         const searchForm = $('.onedotcom-page-search form');
//         const searchInput = $('.onedotcom-page-search .search-box-input');
//
//         searchInput.attr({
//             id: 'search-box-input',
//             autocomplete: 'off',
//         });
//
//         const autocompleteOptions = {
//             appendTo: $('main'),
//             form: searchForm,
//             formAction: ACC.searchUrl,
//             inputField: searchInput,
//             delay: 500,
//             minLength: 3,
//             displayProductImages: true,
//             autocompleteUrl: ACC.autocompleteUrl,
//             encodedContextPath: ACC.config.encodedContextPath,
//         };
//
//         // eslint-disable-next-line no-undef
//         addAutocomplete(autocompleteOptions);
//     }
// });

// --- ONLY HYBRIS --- Add value in search result page
$(window).on('load', () => {
    const searchResultValue = $('.js-search-results-text span');
    if (typeof addAutocomplete !== 'undefined' && searchResultValue.length) {
        const searchInput = $('.onedotcom-page-search .search-box-input');
        searchInput.val(searchResultValue.text());
    }
});

/* add Attr for usassessments header search */
(function () {
    const mainAttr = $('main').attr('data-sitename');
    if (mainAttr === 'usassessments' || mainAttr === 'ustalentlens') {
        const searchForm = $('.js-header-component .onedotcom-page-search form');
        // eslint-disable-next-line no-unused-vars
        const searchInput = searchForm.find('.search-box-input');
        searchForm.attr('action', ACC.searchUrl);
        searchForm.attr('novalidate', true);
    }
}());

/*  Remove class from header

    Purpose: Removes class which activated preloader in header
    navigation.
    ================================================== */
$(document).ready(() => {
    $('.js-preloader').removeClass('js-preloader');
});

/*  $smartResize

    Purpose: do not rapid fire events on resize.
    Author: Paul Irish; ref: http://www.paulirish.com
    /2009/throttled-smartresize-jquery-event-handler
    ================================================== */
// eslint-disable-next-line prefer-rest-params,max-len
(function ($, sr) { const debounce = function (func, threshold, execAsap) { let timeout; return function debounced() { const obj = this; const args = arguments; function delayed() { if (!execAsap) { func.apply(obj, args); }timeout = null; } if (timeout) { clearTimeout(timeout); } else if (execAsap) { func.apply(obj, args); }timeout = setTimeout(delayed, threshold || 100); }; }; jQuery.fn[sr] = function (fn) { return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); }; }(jQuery, 'smartresize'));

$(document).ready(() => {
    // Disable select dropdown if there is only one country option
    if ($('#address\\.country option').length === 2) {
        $('#address\\.country').addClass('not-allowed');
        if (ACC.utils.isHedTheme()) {
            $('#address\\.country option')[0].removeAttribute('selected');
            $('#address\\.country option')[1].setAttribute('selected', true);
            $('#address\\.country').prop('disabled', true);
        }
    }

    if (!ACC.utils.isHedTheme()) {
        ACC.utils.preselectValue('#defaultAddressCountrySelector');
        ACC.utils.preselectValue('#registerNewCompanyMiniForm\\.orgAddressCountrySelector');
        ACC.utils.preselectValue('#address\\.country');
    }

    if (!ACC.utils.isHedTheme()) {
        ACC.utils.preselectValue('#defaultAddressCountrySelector');
        ACC.utils.preselectValue('#registerNewCompanyMiniForm\\.orgAddressCountrySelector');
        ACC.utils.preselectValue('#address\\.country');
    }

    // Add disabled class to i element
    $('select:disabled, select.not-allowed').prev().addClass('disabled');

    if (!ACC.utils.isHedTheme() && !$('body').hasClass('template-pages-RegisterPersonaPageTemplate') && !$('body').hasClass('template-pages-account-manageProfileLayoutPage')) {
        $('select:disabled').closest('.form-group').addClass('hidden');
    }

    // Add not-allowed class to wrapper element
    $('select.not-allowed').parent().addClass('not-allowed');

    // Disabling coupon button if input is empty
    $('#js-voucher-apply-btn').attr('disabled', 'disabled');

    $('#js-voucher-code-text').on('input', function () {
        let empty = false;

        if ($(this).val() === '') {
            empty = true;
        }

        if (empty) {
            $('#js-voucher-apply-btn').attr('disabled', 'disabled');
        } else {
            $('#js-voucher-apply-btn').removeAttr('disabled');
        }
    });

    // Accordion panel

    // this function is to avoid slideToggle jQuery jump bug.
    $.fn.slideShow = function (time, easing) {
        return $(this).animate({
            height: 'show',
            'margin-top': 'show',
            'margin-bottom': 'show',
            'padding-top': 'show',
            'padding-bottom': 'show',
            opacity: 1,
        }, time, easing);
    };
    $.fn.slideHide = function (time, easing) {
        return $(this).animate({
            height: 'hide',
            'margin-top': 'hide',
            'margin-bottom': 'hide',
            'padding-top': 'hide',
            'padding-bottom': 'hide',
            opacity: 0,
        }, time, easing);
    };

    // .each() is used to create a closure to store a cache of the query. Only if they aren't inside a modal.
    if (!$('#cboxLoadedContent').length) {
        $('.accordion-panel-header, .facet-data').each(function () {
            const $content = $(this).parent().next('.accordion-panel-content, .facet__values');

            $(this).on('click', function (e) {
                e.preventDefault();
                const $this = $(this);
                if ($content.is(':hidden')) {
                    $this.removeClass('toggled').attr('aria-expanded', true);
                    $content.not(':animated')
                        .slideShow(320, 'easeOutQuart')
                        .addClass('accordion-panel-show');
                } else {
                    $this.addClass('toggled').attr('aria-expanded', false);
                    $content.not(':animated')
                        .slideHide(320, 'easeOutQuart')
                        .removeClass('accordion-panel-show');
                }
            });
        });
    }

    // Product filters accordion
    // The accrodion are bundlend on the above snippet with ".facet-data" AND ".facet__values"

    // Product filters accordion ends

    // responsive table wrapper

    $('.description table').wrap('<div class="responsive-table-wrapper" />');

    // smooth scrolling for anchor tags
    let $root = $('html, body');

    $('a[href^="#top"]').click(function () {
        $root.animate({
            scrollTop: $($.attr(this, 'href')).offset().top,
        }, 500);

        return false;
    });

    // smooth scroll to top
    if (ACC.utils.isHedTheme()) {
        const backToTopBtn = document.querySelector('.to-top-button');
        const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

        if (backToTopBtn) {
            backToTopBtn.addEventListener('click', (e) => {
                e.preventDefault();
                const elem = document.querySelector(e.currentTarget.getAttribute('href'));

                if (isSmoothScrollSupported) {
                    elem.scrollIntoView({
                        behavior: 'smooth',
                    });
                } else {
                    $('html, body').animate({
                        scrollTop: $($.attr(e.target, 'href')).offset().top,
                    }, 500);
                }
            });
        }
    }

    // Nested Tabs

    // Convert div to nav
    $('.nested-tabs').replaceWith(`<nav class='nested-tabs'>${$('.nested-tabs').html()}</nav>`);

    // Smooth scroll to anchor links
    $root = $('html, body');

    $('.nested-tabs a[href^="#"]').click(function () {
        const href = $.attr(this, 'href');

        $root.animate({
            scrollTop: $(href).offset().top,
        }, 500, () => {
            window.location.hash = href;
        });

        return false;
    });
});

// When a new option is chosen, remode the .disabled class from the next dropdown and show
// it by remmoving the .hidden class
$('.form-control').change(function () {
    const nextFormGroup = $(this).closest('.form-group').next();
    if ($(this).val() !== '0' && !nextFormGroup.hasClass('not-shown')) {
        nextFormGroup.removeClass('hidden');
        nextFormGroup.find('.disabled').removeClass('disabled');
    }
});

/*  $Table-to-Grid Conversion

    Purpose: convert existing CKEditor <table> markup
    into Bootstrap grid
    ================================================== */
(function table_to_grid() {
    if (!$('body').is('.cke_editable')) {
        // look for <table> els that have class of "grid--*" applied to them (example: "grid--25-50-25")
        const table_to_convert = $('table[class*="grid-layout--"]');

        if (table_to_convert.length > 0) {
            table_to_convert
                .each(function () {
                    const table = $(this);
                    const tbody = table.find('> tbody');
                    // eslint-disable-next-line no-unused-vars
                    const tr = tbody.find('> tr');
                    const caption = table.find('caption');

                    // caption
                    caption
                        .remove();//

                    table
                        .each(function () {
                            const el = $(this);

                            // 1 column
                            if (el.is('.grid-layout--100')) {
                                // eslint-disable-next-line no-use-before-define
                                grid_check($(this), 1);
                            }

                            // 2 columns
                            // eslint-disable-next-line max-len
                            if (el.is('.grid-layout--25-75') || el.is('.grid-layout--75-25') || el.is('.grid-layout--50-50') || el.is('.grid-layout--33-67') || el.is('.grid-layout--67-33')) {
                                // eslint-disable-next-line no-use-before-define
                                grid_check($(this), 2);
                            }

                            // 3 columns
                            // eslint-disable-next-line max-len
                            if (el.is('.grid-layout--17-66-17') || el.is('.grid-layout--50-25-25') || el.is('.grid-layout--25-50-25') || el.is('.grid-layout--25-25-50') || el.is('.grid-layout--equal-x-3')) {
                                // eslint-disable-next-line no-use-before-define
                                grid_check($(this), 3);
                            }

                            // 4 columns
                            if (el.is('.grid-layout--equal-x-4')) {
                                // eslint-disable-next-line no-use-before-define
                                grid_check($(this), 4);
                            }

                            // 6 columns
                            if (el.is('.grid-layout--equal-x-6')) {
                                // eslint-disable-next-line no-use-before-define
                                grid_check($(this), 6);
                            }
                        });

                    let convert_to_grid = $('.convert-to-grid');

                    // tables greenlighted for conversion
                    convert_to_grid
                        .each(function () {
                            const ok_table = $(this);
                            const grid_layout = ok_table.attr('class');
                            const ok_tbody = ok_table.find('> tbody');
                            const ok_tr = ok_tbody.find('> tr');
                            const ok_cell = ok_tr.find('> td, > th');

                            ok_cell
                                .each(function () {
                                    $(this)
                                        .contents()
                                        .unwrap()
                                        .wrapAll('<div class="col-xs-12" />');
                                });

                            ok_tr
                                .each(function () {
                                    $(this)
                                        .contents()
                                        .unwrap()
                                        .wrapAll('<div class="row" />');
                                });

                            // tbody
                            ok_tbody
                                .contents()
                                .unwrap()
                                .wrapAll('<div class="container" />');

                            // table
                            ok_table
                                .contents()
                                .unwrap()
                                .wrapAll(`<section class="${grid_layout}" />`);
                        });

                    convert_to_grid = $('.convert-to-grid');// need to set again after unwrap/wrap?

                    convert_to_grid
                        .removeClass('convert-to-grid')
                        .addClass('converted-to-grid');

                    const ok_grid = $('.converted-to-grid');// sections already converted

                    ok_grid
                        .each(function () {
                            const layout = $(this);
                            const ok_row = layout.find('> .container > .row');

                            // full width
                            if (layout.is('.grid-layout--100')) {
                                ok_row.each(function () {
                                    $(this)
                                        .find('>[class*="col-"]')
                                        .addClass('col-sm-12');
                                });
                            }

                            // 3-9
                            if (layout.is('.grid-layout--25-75')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-3')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-9');
                                    });
                            }

                            // 9-3
                            if (layout.is('.grid-layout--75-25')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-9')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-3');
                                    });
                            }

                            // 6-6
                            if (layout.is('.grid-layout--50-50')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-6')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-6');
                                    });
                            }

                            // 4-8
                            if (layout.is('.grid-layout--33-67')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-4')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-8');
                                    });
                            }

                            // 8-4
                            if (layout.is('.grid-layout--67-33')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-8')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-4');
                                    });
                            }

                            // 2-8-2
                            if (layout.is('.grid-layout--17-66-17')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-2')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-8')
                                            .end()
                                            .eq(2)
                                            .addClass('col-sm-2');
                                    });
                            }

                            // 6-3-3
                            if (layout.is('.grid-layout--50-25-25')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-6')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-3')
                                            .end()
                                            .eq(2)
                                            .addClass('col-sm-3');
                                    });
                            }

                            // 3-6-3
                            if (layout.is('.grid-layout--25-50-25')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-3')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-6')
                                            .end()
                                            .eq(2)
                                            .addClass('col-sm-3');
                                    });
                            }

                            // 3-3-6
                            if (layout.is('.grid-layout--25-25-50')) {
                                ok_row
                                    .each(function () {
                                        $(this)
                                            .find('>[class*="col-"]')
                                            .eq(0)
                                            .addClass('col-sm-3')
                                            .end()
                                            .eq(1)
                                            .addClass('col-sm-3')
                                            .end()
                                            .eq(2)
                                            .addClass('col-sm-6');
                                    });
                            }

                            // 3 equal
                            if (layout.is('.grid-layout--equal-x-3')) {
                                ok_row.each(function () {
                                    $(this)
                                        .find('>[class*="col-"]')
                                        .addClass('col-sm-4');
                                });
                            }

                            // 4 equal
                            if (layout.is('.grid-layout--equal-x-4')) {
                                ok_row.each(function () {
                                    $(this)
                                        .find('>[class*="col-"]')
                                        .addClass('col-sm-3');
                                });
                            }

                            // 6 equal
                            if (layout.is('.grid-layout--equal-x-6')) {
                                ok_row.each(function () {
                                    $(this)
                                        .find('>[class*="col-"]')
                                        .addClass('col-sm-2');
                                });
                            }
                        });
                });// end table_to_convert.each fn()
        }// end if table_to_convert length check

        // validate grid col count
        // eslint-disable-next-line no-inner-declarations
        function grid_check(table, correct) {
            const total_rows = table.find('> tbody > tr').length;
            const total_cells = table.find('> tbody > tr > td, > tbody > tr > th').length;
            const grid_cells = correct;
            const correct_count = total_rows * grid_cells;

            if (total_cells !== correct_count) {
                table
                    .addClass('grid-error')
                    .attr('data-msg', 'GRID ERROR: incorrect number of columns for layout selected.');
            } else {
                table
                    .addClass('convert-to-grid')
                    .removeAttr('data-msg');
            }
        }
    }// end check (if .cke_editable mode)
}());// end table_to_grid fn()

/*  Append copyright to footer
    ================================================== */
(function currentYear() {
    if (ACC.config.footerCopyrightDisabled === 'true') {
        return;
    }
    const footer = $('footer'); const
        legal = footer.find('.footer-legal-notes');
    let copyRightText = 'All rights reserved';
    if (document.documentElement.lang.toLowerCase() === 'fr_ca'
        || document.documentElement.lang.toLowerCase() === 'fr') {
        copyRightText = 'Tous droits réservés';
    }
    // eslint-disable-next-line max-len
    legal.find('ul').after(`<p class="match-footer-link copyright-data-container">Copyright © 1996–<span class="current-year"></span>&nbsp;<a href="https://www.pearson.com" aria-label="Pearson" title="Pearson" >Pearson</a>&nbsp;${copyRightText}.</p>`);
    $('.current-year').html((new Date()).getFullYear());
}());

// Polyfill for dinosaurs that don't support startsWith
// see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.startsWith = function (search, pos) {
        return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
    };
}

/*
    Friendly tabs
    ================================================== */
// eslint-disable-next-line no-unused-vars
function friendlyTabs() {
    const urlQuery = (function (query) {
        if (query === '') {
            return {};
        }
        const arr = {};
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < query.length; ++i) {
            const p = query[i].split('=', 2);
            if (p.length === 1) {
                arr[p[0]] = '';
            } else {
                arr[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
            }
        }
        return arr;
    }(window.location.search.substr(1).split('&')));

    if ($('.tabs-wrapper').length > 0) {
        const tab_nav = $('.tabs-list').find('[role="presentation"]');
        const tab_links = tab_nav.find('[role="tab"]');
        const location_no_query = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        const doc_title = document.title;
        const mobile_tab_links = tab_nav.find('a');

        tab_links.add(mobile_tab_links).each(function () {
            const el = $(this);
            const original_text = el.text();
            const formatted_text = encodeURIComponent(original_text.trim().replace(/\s+/g, '-').toLowerCase());
            const tab_location_search = JSON.parse(JSON.stringify(urlQuery));
            const new_document_title = `${doc_title} | ${original_text}`;

            tab_location_search.tab = formatted_text;

            el.attr({
                'data-tab-query-url': `${location_no_query}?${$.param(tab_location_search)}`,
                href: location_no_query,
            });

            if (urlQuery.tab === formatted_text) {
                // if matching link's parent is not the currently active tab
                if (!el.parent().is('.active')) {
                    el.trigger('click');
                    $('html, body').animate({ scrollTop: $('ul.tabs-list').offset().top }, 500);
                    document.title = new_document_title;
                }
            }

            // set click event for links
            el.on('click', (e) => {
                e.preventDefault();
                const tab_query_url = el.attr('data-tab-query-url');
                if (el.attr('data-tab-query-url') !== window.location.href) {
                    window.history.pushState(null, new_document_title, tab_query_url);
                    document.title = new_document_title;
                    $('html, body').animate({ scrollTop: $('ul.tabs-list').offset().top }, 500);
                }
            });

            // popstate
            $(window).on('popstate', () => {
                if (window.location.search.indexOf('tab=') > -1) {
                    if (el.attr('data-tab-query-url') === window.location.href) {
                        if (!el.parent().is('.active')) {
                            el.trigger('click');
                        }
                    }
                } else {
                    // no location.search
                    window.location.href = location_no_query;
                }
            });
        });
    }
}

/*  $Accordion-Component

    Purpose: global accordion component use + CKEditor
    conversion of <table> markup into <dl> based
    accordion
    ================================================== */
(function table_to_accordion() {
    if (!$('body').is('.cke_editable')) {
        const table_to_convert = $('table[class*="accordion-component"]');

        if (table_to_convert.length > 0) {
            table_to_convert
                .each(function () {
                    const table = $(this);
                    const tbody = table.find('> tbody');
                    // eslint-disable-next-line no-unused-vars
                    const tr = tbody.find('> tr');
                    const caption = table.find('caption');
                    const classes = table.attr('class');

                    // caption
                    caption
                        .remove();//

                    table
                        .each(function () {
                            // eslint-disable-next-line no-unused-vars
                            const el = $(this);
                            // eslint-disable-next-line no-use-before-define
                            accordion_check($(this));
                        });

                    const convert_to_accordion = $('.convert-to-accordion');

                    // tables greenlighted for conversion
                    convert_to_accordion
                        .each(function () {
                            const ok_table_accordion = $(this);
                            const ok_tbody_accordion = ok_table_accordion.find('> tbody');
                            const ok_tr_accordion_trigger = ok_tbody_accordion.find('> tr').filter(':even');
                            const ok_tr_accordion_target = ok_tbody_accordion.find('> tr').filter(':odd');
                            const ok_cell_accordion = ok_tr_accordion_trigger
                                .add(ok_tr_accordion_target)
                                .find('> td, > th');

                            // unwrap <td> and <th> content
                            ok_cell_accordion
                                .each(function () {
                                    $(this)
                                        .contents()
                                        .unwrap();
                                });

                            // trigger (toggle control)
                            ok_tr_accordion_trigger
                                .each(function () {
                                    const el = $(this);

                                    if (el.children().length > 0) {
                                        const el_clean = el.text().trim();

                                        el
                                            // eslint-disable-next-line max-len
                                            .html(`<button class="accordion__trigger" aria-expanded="false"><span>${el_clean}</span>`)
                                            .contents()
                                            .unwrap()
                                            .wrapAll('<dt class="accordion__header">');
                                    } else {
                                        el
                                            .contents()
                                            .unwrap()
                                            // eslint-disable-next-line max-len
                                            .wrapAll('<dt class="accordion__header"><button class="accordion__trigger" aria-expanded="false"><span />');
                                    }
                                });

                            // target (content)
                            ok_tr_accordion_target
                                .each(function () {
                                    $(this)
                                        .contents()
                                        .unwrap()
                                        .wrapAll('<dd class="accordion__content">'
                                            + '<div class="accordion__content-wrapper" />');
                                });

                            // tbody
                            ok_tbody_accordion
                                .contents()
                                .unwrap()
                                .wrapAll('<dl class="accordion__component" />');

                            // table
                            ok_table_accordion
                                .contents()
                                .unwrap()
                                .addClass(classes)
                                .removeClass('accordion-component', '[class*="grid-layout--"]');
                        });
                });// end table_to_convert.each fn()
        }// end if table_to_convert length check

        // validation: accordion els count (only a single cell per row allowed)
        // eslint-disable-next-line no-inner-declarations
        function accordion_check(table) {
            const total_rows = table.find('> tbody > tr').length;
            const total_cells = table.find('> tbody > tr > td, > tbody > tr > th').length;

            if (total_rows !== total_cells) {
                table
                    .addClass('accordion-component--error');
            } else {
                table
                    .addClass('convert-to-accordion');
            }
        }
    }// end check (if .cke_editable mode)
}());// end table_to_accordion fn()

/*  $Accordion

    Purpose: share a global accordion method
    ================================================== */
(function accordion() {
    // provide additional attributes for trigger els
    if ($('.accordion__trigger').length > 0) {
        const button_trigger = $('.accordion__trigger');

        button_trigger
            .each(function (j) {
                const el = $(this);
                const original_text = el.text().trim();
                const formatted_text = original_text.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-').toLowerCase();
                const identifier = `filter--${formatted_text}--${j}`;

                el
                    .attr('aria-expanded', 'false')
                    .parent()
                    .addClass('accordion__header')
                    .attr('id', identifier);

                // if no expand trigger icon found add one
                if (!el.find('.accordion__trigger--expand-icon').length) {
                    $('<span class="accordion__trigger--expand-icon" />').appendTo(el);
                }

                // eslint-disable-next-line no-plusplus
                ++j;
            });
    }

    // expand panel(s) if class is present
    const accordion_component = $('.accordion__component');

    accordion_component
        .each(function () {
            // first > panel only
            if ($(this).is('.expand-first-panel')) {
                const expand_first_panel = $('.expand-first-panel');

                expand_first_panel
                    .each(function () {
                        const efp = $(this);
                        const efp_accordion_header = efp.find(' > .accordion__header:first-of-type');
                        const efp_first_trigger = efp_accordion_header.find(' > button.accordion__trigger');
                        const efp_first_target = efp_accordion_header.next();

                        efp_first_trigger
                            .attr('aria-expanded', 'true');

                        efp_accordion_header
                            .addClass('trigger--is-expanded');

                        efp_first_target
                            .addClass('target--is-visible');
                    });
            }

            // all > panels
            if ($(this).is('.expand-all-panels')) {
                const expand_all_panels = $('.expand-all-panels');

                expand_all_panels
                    .each(function () {
                        const eap = $(this);
                        const eap_accordion_header = eap.find(' > .accordion__header');
                        const eap_trigger = eap_accordion_header.find(' > button.accordion__trigger');
                        const eap_target = eap_accordion_header.next();

                        eap_trigger
                            .attr('aria-expanded', 'true');

                        eap_accordion_header
                            .addClass('trigger--is-expanded');

                        eap_target
                            .addClass('target--is-visible');
                    });
            }
        });

    // set click event behavior for newly added triggers (accordion header)
    $(document)
        .on('click', 'button.accordion__trigger', function (event) {
            event.preventDefault();

            const trigger = $(this);
            const trigger_parent = trigger.parent();
            const target = trigger_parent.next();

            // if content is exposed
            if (trigger.is('[aria-expanded="true"]')) {
                trigger
                    .attr('aria-expanded', 'false');
                trigger_parent
                    .removeClass('trigger--is-expanded');
                target
                    .removeClass('target--is-visible');
            } else {
                trigger
                    .attr('aria-expanded', 'true');
                trigger_parent
                    .addClass('trigger--is-expanded');
                target
                    .addClass('target--is-visible').trigger('contentvisible');
            }
        });
}());// end accordion fn()
